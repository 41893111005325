import React from "react";
import InsertCode from "./InsertCode";
import { APP_URL } from "config/environment";
import { useParams } from "react-router-dom";

function CodeAssociation() {
  const [code, setCode] = React.useState("");
  const [defaultGreeting, setDefaultGreeting] = React.useState(false);
  const { encryptedClientID } = useParams<{ encryptedClientID: string }>();

  const handleDefaultGreeting = React.useCallback(() => {
    setDefaultGreeting(!defaultGreeting);
    setCode(" ")
  }, [defaultGreeting]);

  const handleCodeSubmit = React.useCallback(() => {
    if (defaultGreeting) {
      const storyURL = new URL(
        "/story/" + encryptedClientID?.toLowerCase() + "?d=d",
        APP_URL
      ).toString();
      window.location.href = storyURL;
      return
    }
    const storyURL = new URL(
      "/story/" + code?.toLowerCase(),
      APP_URL
    ).toString();
    window.location.href = storyURL;
  }, [code, encryptedClientID, defaultGreeting])

  React.useEffect(() => {
    if (defaultGreeting) {
      handleCodeSubmit();
    }
  }, [defaultGreeting]);

  return <InsertCode
    handleSubmit={handleCodeSubmit}
    handleDefaultGreeting={handleDefaultGreeting}
    setCode={setCode}
    code={code}
  />;
}

export default CodeAssociation;
